<template>
  <v-col cols="12">
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card>
      <v-card-title>Pages</v-card-title>

      <v-card-text>
        <div class="global-actions">
          <router-link :to="{ name: 'pageCreate' }">
            <v-btn
              small
              color="primary"
              class="mt-5"
            >
              Ajouter une page
            </v-btn>
          </router-link>
        </div>
      </v-card-text>

      <v-row class="mb-5">
        <v-col
          class="tags-search ml-5"
          cols="2"
          sm="2"
        >
          <vue-tags-input
            v-model="tag"
            :tags="tags"
            placeholder="Recherche"
            @tags-changed="newTags => {
              tags = newTags
              search()
            }"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-btn
            class="mt-1"
            small
            color="info"
            @click="search()"
          >
            <v-icon
              small
              class="mr-1"
            >
              {{ icons.mdiDatabaseSearchOutline }}
            </v-icon>
            <i class="fas fa-search"></i>
            Rechercher
          </v-btn>
          <v-btn
            small
            color="info"
            class="ml-2 mt-1"
            @click="reset()"
          >
            <v-icon
              small
              class="mr-1"
            >
              {{ icons.mdiBackupRestore }}
            </v-icon>
            Réinitialisation
          </v-btn>
        </v-col>
      </v-row>

      <div class="clear"></div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Page
              </th>
              <th class="text-center text-uppercase">
                Menu
              </th>
              <th class="text-center text-uppercase">
                Etat
              </th>
              <th class="text-center text-uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody v-if="pages.length">
            <tr
              v-for="(page, pageIndex) in pages"
              :key="'p' + pageIndex"
            >
              <td class="font-weight-bold">
                {{ page.title }}
              </td>
              <td class="text-center">
                <v-chip
                  v-if="page.inMenu"
                  color="success"
                  text-color="white"
                  small
                >
                  Oui
                </v-chip>
                <v-chip
                  v-if="!page.inMenu"
                  color="error"
                  text-color="white"
                  small
                >
                  Non
                </v-chip>
              </td>
              <td class="text-center">
                <v-chip
                  v-if="page.enabled"
                  color="success"
                  text-color="white"
                  small
                >
                  Activée
                </v-chip>
                <v-chip
                  v-if="!page.enabled"
                  color="error"
                  text-color="white"
                  small
                >
                  Désactivée
                </v-chip>
              </td>
              <td class="text-center">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      :to="{ name: 'pageDetails', params: { pageId: page.id }}"
                    >
                      <v-icon dark>
                        {{ icons.mdiEye }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Consulter</span>
                </v-tooltip>

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      color="error"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      @click="openDeletePageDialog(page)"
                    >
                      <v-icon dark>
                        {{ icons.mdiTrashCan }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!pages.length">
            <tr>
              <td>
                <span v-if="loading === false">Aucun éléments.</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <DeletePageDialog
        :key="Date.now()"
        :delete-page-dialog-state="deletePageDialogState"
        :page="pageToDelete"
        @closeDeletePageDialog="closeDeletePageDialog($event)"
      ></DeletePageDialog>
    </v-card>
  </v-col>
</template>

<script>
import { mdiPlay, mdiPen, mdiEye, mdiTrashCan, mdiCheck, mdiCloseCircleOutline, mdiBackupRestore, mdiDatabaseSearchOutline } from '@mdi/js'
import Vue from 'vue'
import DeletePageDialog from '@/components/Dialog/ContentDialog/Page/DeletePageDialog'
import config from '../../../config'
import Loader from '@/components/Common/Loader'
import VueTagsInput from '@johmun/vue-tags-input'

export default {
  components: {
    DeletePageDialog,
    Loader,
    VueTagsInput
  },
  data: () => ({
    nextPage: 1,
    tag: '',
    tags: [],
    pages: [],
    pageToDelete: null,
    deletePageDialogState: false,
    loading: false,
    icons: {
      mdiPlay,
      mdiPen,
      mdiEye,
      mdiTrashCan,
      mdiCheck,
      mdiCloseCircleOutline,
      mdiBackupRestore,
      mdiDatabaseSearchOutline
    }
  }),
  computed: {
    isHandlingPageScroll () {
      return this.$store.getters.isHandlingPageScroll
    }
  },
  created () {
    this.getPages()

    window.removeEventListener('scroll', this.handleScroll)

    setTimeout(() => {
      window.addEventListener('scroll', this.handleScroll)
    }, 1000)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const wrapperOffsetHeight = document.querySelector('.v-application--wrap').offsetHeight
      const bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === wrapperOffsetHeight

      if (bottomOfWindow && !this.isHandlingPageScroll) {
        this.getPages()
      }
    },
    getPages () {
      this.loading = true

      const params = {
        page: this.nextPage
      }

      const terms = []

      for (let i = 0; i < this.tags.length; i++) {
        terms.push(this.tags[i].text)
      }

      if (terms.length) {
        params.terms = terms.join(',')
      }

      this.$store.commit('setHandlingPageScroll', true)

      Vue.prototype.$http
        .get(config.apiUrl + '/api/content/pages', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const pages = response.data

            if (pages && pages.length) {
              this.pages.push(...pages)

              this.nextPage = this.nextPage + 1
            }
          }

          this.loading = false
          this.$store.commit('setHandlingPageScroll', false)
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
          this.$store.commit('setHandlingPageScroll', false)
        })
    },
    search () {
      const params = {
        page: 1
      }

      const terms = []

      for (let i = 0; i < this.tags.length; i++) {
        terms.push(this.tags[i].text)
      }

      if (terms.length) {
        params.terms = terms.join(',')
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/api/content/pages', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            this.pages = response.data

            this.nextPage = 2
          }
        })
        .catch(() => {
        })
    },
    reset () {
      this.tag = ''
      this.tags = []

      this.search()
    },
    openDeletePageDialog (page) {
      this.deletePageDialogState = true
      this.pageToDelete = page
    },
    closeDeletePageDialog (deleteState) {
      if (deleteState === true) {
        this.pages = this.pages.filter(element => element.id !== this.pageToDelete.id)
      }

      this.deletePageDialogState = false
    }
  }
}
</script>
